<template>
  <div class="detail" v-if="detail">
    <el-form ref="form" label-width="120px">
      <el-form-item label="投诉人:">
        <el-col :span="24">
          <span>{{ detail.contact_name }}</span>
        </el-col>
      </el-form-item>
      <el-form-item label="被投诉人:">
        <el-col :span="24">
          <span>{{ detail.worker_name }}</span>
        </el-col>
      </el-form-item>
      <el-form-item label="问题详情:" style="margintop: 50px">
        <el-col :span="24">
          <span>{{ detail.complaint_content }}</span>
        </el-col>
      </el-form-item>
      <el-form-item label="状态:" style="margintop: 50px">
        <el-col :span="2">
          <span v-if="detail.complaint_status == 1" style="color: #02ce80"
            >已处理</span
          >
          <span v-else style="color: #e90000">未处理</span>
        </el-col>
        <el-col :span="2" v-if="detail.complaint_status == 0">
          <MyButton type="primary" @click="dialogVisible = true">处理</MyButton>
        </el-col>
      </el-form-item>
      <el-form-item label="处理人:" v-if="detail.complaint_status == 1">
        <el-col :span="3">
          <span>{{ detail.market_worker_name }}</span>
        </el-col>
        <el-col :span="18">
          <span style="color: #999999">处理结果：</span>
          <span>{{ detail.handle_feedback }}</span>
        </el-col>
      </el-form-item>
      <el-form-item
        label="问题图片:"
        style="margintop: 50px"
        v-if="detail.complaint_images.length > 0"
      >
        <el-col :span="24">
          <div class="imgList">
            <img
              :src="item"
              v-img
              v-preview
              class="imgBox"
              alt=""
              v-for="(item, index) in detail.complaint_images"
              :key="index"
            />
          </div>
        </el-col>
      </el-form-item>
    </el-form>
    <el-dialog
      title="投诉处理"
      :visible.sync="dialogVisible"
      width="880px"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="处理结果" prop="handle_feedback">
          <el-col :span="14">
            <el-input
              v-model="ruleForm.handle_feedback"
              type="textarea"
              class="public-input"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton right title="关闭" @click="dialogVisible = false"></MyButton>
        <MyButton title="确认" type="primary" @click="confirm"></MyButton>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "detail",
  data() {
    return {
      detail: null,
      id: "",
      dialogVisible: false,
      ruleForm: {
        handle_feedback: "",
      },
      rules: {
        handle_feedback: [
          { required: true, message: "请输入处理结果", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$request
        .HttpGet("/market_complaint/edit", {
          id: this.id,
        })
        .then((res) => {
          res.data.complaint_images = JSON.parse(res.data.complaint_images);
          let arr = [];
          res.data.complaint_images.forEach((item) => {
            arr.push(process.env.VUE_APP_IMG_URL + item);
          });
          res.data.complaint_images = arr;
          this.detail = res.data;
        });
    },
    handleClose() {
      this.ruleForm.handle_feedback = "";
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$request
            .HttpPost("/market_complaint/edit", {
              complaint_id: this.id,
              complaint_status: 1,
              handle_feedback: this.ruleForm.handle_feedback,
            })
            .then(() => {
              this.$common.notifySuccess("已处理");
              this.dialogVisible = false;
              this.$router.push("/complain/complainlist/pending");
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  margin-top: 40px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  /deep/ .el-form-item__label {
    color: #999999;
  }
  .imgList {
    display: flex;
    img {
      width: 167px;
      height: 120px;
      margin-right: 14px;
    }
  }
}
</style>
